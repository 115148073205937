function buildCookieString (name, value, { expires, maxAge } = {}) {
	let result = value || '';

	if (!value.includes('domain=')) {
		const domainBlocks = document.domain.split('.');
		const ext = domainBlocks[domainBlocks.length - 1];
		result += `;domain=beekast.${ext}`;
	}
	if (!value.includes('path=')) {
		result += ';path=/';
	}

	if (expires) {
		result += ';expires=' + expires;
	}

	if (maxAge) {
		result += ';max-age=' + maxAge;
	}

	return `${name}=${result}`;
}

function getCookie (name) {
	return document.cookie.split(/\s*;\s*/).find((cookie) => [name, `${name}-legacy`].includes(cookie.split('=')[0]));
}

function getCookieValue (name) {
	const cookie = getCookie(name);
	if (!cookie) {
		return null;
	}
	return cookie.split('=')[1];
}

function isSameSiteCookieSupported () {
	setSameSiteCookie('SAMESITE_SUPPORT', 'TEST');
	const isSupported = Boolean(getCookieValue('SAMESITE_SUPPORT'));
	setSameSiteCookie('SAMESITE_SUPPORT', '', { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' });
	return isSupported;
}

function setCookie (name, value, options) {
	if (isSameSiteCookieSupported()) {
		setSameSiteCookie(name, value, options);
	} else {
		setLegacyCookie(name, value, options);
	}
}

function deleteCookie (name) {
	setCookie(name, '', { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' });
}

function setSameSiteCookie (name, value, options) {
	document.cookie = `${buildCookieString(name, value, options)};samesite=None;Secure`;
}

function setLegacyCookie (name, value, options) {
	let cookie = buildCookieString(`${name}-legacy`, value, options);
	if (process.env.NODE_ENV === 'production') {
		cookie += ';Secure';
	}
	document.cookie = cookie;
}

export { getCookie, getCookieValue, setCookie, deleteCookie };
